.page-comingsoon {
  background: #1c0a2d;

  .bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    #bg0 {
      max-width: unset;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right bottom;

      @media screen and (max-width: 480px) {
        width: 200%;
        height: 100vh;
        margin-left: -80%;
      }

      @media screen and (max-width: 400px) {
        margin-left: -20%;
        transform: scale(0.6);
        transform-origin: left bottom;
      }
    }

    #bg1 {
      position: absolute;
      min-width: 568px;
      min-height: 568px;
      width: 54.236vw;
      height: 54.236vw;
      left: 28.942vw;
      top: -21.667vw;

      background: radial-gradient(
        50% 50% at 50% 50%,
        #5e5b9c 0%,
        rgba(140, 134, 255, 0) 100%
      );
      opacity: 0.2;

      transform-origin: 40% 45%;
      animation: rotate 6s infinite forwards linear;
    }

    #bg2 {
      position: absolute;
      width: 39.951vw;
      height: 39.951vw;
      min-width: 418px;
      min-height: 418px;
      left: 53.599vw;
      top: -4.074vw;

      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(15, 149, 226, 0.8) 0%,
        rgba(15, 149, 226, 0) 100%
      );
      opacity: 0.31;

      transform-origin: 60% 45%;
      animation: rotate 8s infinite forwards linear;
    }

    #bg3 {
      position: absolute;
      width: 66.508vw;
      height: 66.508vw;
      min-width: 696px;
      min-height: 696px;
      left: 24.722vw;
      top: -14.91vw;

      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(204, 55, 103, 0.41) 0%,
        rgba(215, 89, 123, 0) 100%
      );
      opacity: 0.7;

      transform-origin: 52% 65%;
      animation: rotate 9s infinite reverse linear;
    }

    .grad {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 400px) {
      .grad {
        transform: translateX(-50%);
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .container {
    position: relative;
    padding-top: 3rem;
    // transition: transform 0.1s ease;

    @media screen and (max-width: 800px) {
      padding-top: 4vh;
    }
  }

  .logo {
    position: relative;
    left: -27px;
    display: inline-block;

    img {
      width: 252px;
      height: auto;
    }

    svg {
      filter: drop-shadow(0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5));
    }

    @media screen and (max-width: 800px) {
      transform: scale(0.85);
      transform-origin: left top;
    }
  }

  .content {
    position: relative;
    margin-top: 132px;
    padding-left: 150px;
    padding-top: 40px;

    .border {
      position: absolute;
      left: 70px;
      top: 0;
      height: 540px;
      width: 48px;

      path {
        stroke-dasharray: 540;
        stroke-dashoffset: -540;
        transition: stroke-dashoffset 2s ease-out;
      }
    }

    h3 {
      margin-bottom: 50px;
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 800;
      letter-spacing: 0.1em;
      color: $voidneon;
      text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.75);

      span {
        display: block;
        transition: transform 1s ease-out, opacity 1s ease-out;
        transform: translateY(-20px);
        opacity: 0;
      }
    }

    h1 {
      margin-bottom: 50px;
      font-family: "Outage", sans-serif;
      font-size: 45px;
      font-weight: 700;
      line-height: 1;
      color: $white;
      text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);

      span {
        display: block;
        transition: transform 1s ease-out 0.4s, opacity 1s ease-out 0.4s;
        transform: translateY(-30px);
        opacity: 0;
      }
    }

    h2 {
      position: relative;
      margin-left: 90px;
      margin-bottom: 55px;
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: $white;
      opacity: 0.9;

      span {
        display: block;
        transition: transform 1s ease-out 0.9s, opacity 1s ease-out 0.9s;
        transform: translateY(-20px);
        opacity: 0;
      }

      &::before {
        content: "";
        position: absolute;
        left: -25px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $white;
        opacity: 0.3;
        box-shadow: 0px 0.871355px 8.71355px rgba(140, 134, 255, 0.5);
        transition: transform 1s ease-out 0.9s;
        transform: scaleY(0);
        transform-origin: left top;
      }
    }

    .join {
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.2;
      color: $void5;

      transition: transform 1s ease-out 1.4s, opacity 1s ease-out 1.4s;
      transform: translateY(-20px);
      opacity: 0;

      .links {
        margin-top: 28px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        transition: transform 1s ease-out 1.9s, opacity 1s ease-out 1.9s;
        transform: translateX(-20px);
        opacity: 0;

        a {
          position: relative;
          display: block;
          width: 48px;
          height: 48px;
          margin-right: 20px;
          border-radius: 50%;
          overflow: hidden;
          transform-origin: center;
          transition: transform 0.3s ease-out, filter 0.3s ease-out;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 200%;
            height: 100%;
            background: linear-gradient(
              86.26deg,
              #8c86ff 1.5%,
              #ab5fb4 25%,
              #cc3767 50%,
              #ab5fb4 75%,
              #8c86ff 98.5%
            );
            z-index: 0;
            transition: left 0.3s ease-out;
          }

          svg {
            position: relative;
            z-index: 1;
          }

          &:hover {
            transform: scale(1.1);
            filter: drop-shadow(0px 1px 10px rgba(140, 134, 255, 0.5));

            &::before {
              left: -100%;
            }
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
      padding-left: 30px;
      margin-top: 0;

      .border {
        left: -10px;
      }

      h3 {
        margin-top: 5px;
        margin-bottom: 30px;
        font-size: 15px;
      }

      h1 {
        margin-bottom: 30px;
        font-size: 28px;
      }

      h2 {
        margin-left: 30px;
        margin-bottom: 45px;
        font-size: 17px;
      }

      .join {
        font-size: 15;
      }
    }

    @media screen and (max-width: 480px) {
      h1 br {
        display: none;
      }
    }
  }

  .tags {
    position: absolute;
    right: 0;
    top: 65px;
    overflow: hidden;
    padding-bottom: 2rem;

    .border {
      position: relative;
      right: -9px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;

      path {
        stroke-dasharray: 120;
        stroke-dashoffset: -120;
        transition: stroke-dashoffset 0.3s ease-out 2s;
      }
    }

    .links {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-end;

      a {
        display: block;
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: 0.01em;
        color: transparent;
        transform: translateX(40px);
        opacity: 0;
        transition: transform 0.4s ease-out, opacity 0.4s ease-out;

        &:nth-child(1) {
          transition-delay: 2.4s;
        }
        &:nth-child(2) {
          transition-delay: 2.6s;
        }
        &:nth-child(3) {
          transition-delay: 2.8s;
        }
        &:nth-child(4) {
          transition-delay: 3s;
        }
        &:nth-child(5) {
          transition-delay: 3.2s;
        }

        span {
          position: relative;
          color: $candyneon;
          background: transparent;
          transition: color 0.4s ease-out;

          &::before {
            content: attr(title);
            position: absolute;
            left: 0;
            top: -1px;
            width: 100%;
            height: 100%;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            color: transparent;
            z-index: -1;
            background-clip: text;
            -webkit-background-clip: text;
            background-image: linear-gradient(
              269deg,
              #f1aebb 0.76%,
              #8c86ff 98.71%
            );
          }
        }

        &:hover {
          span {
            color: transparent;
          }
        }
      }
    }

    @media screen and (max-width: 1300px) {
      right: 30px;
    }

    @media screen and (max-width: 800px) {
      position: static;
      right: 0;
      margin-top: -40px;

      .links {
        a {
          font-size: 15px;
        }
      }
    }
  }

  &.play {
    .content {
      h1,
      h2,
      h3 {
        span {
          transform: translateY(0);
          opacity: 1;
        }
      }

      h2 {
        &::before {
          transform: scaleY(1);
        }
      }

      .join {
        transform: translateY(0);
        opacity: 1;

        .links {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    .tags {
      .links {
        a {
          transform: translateX(0);
          opacity: 0.9;
        }
      }
    }

    path {
      stroke-dashoffset: 0 !important;
    }
  }
}
