// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// Regular font family
/// @type List
$text-font-stack: "IBM Plex Sans", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

/// Max width
$max-width: 1240px; // 12 col - 88/20 + 20 padding LR
$content-width: 1080px; // 10 col w/ gutter
$inner-width: 864px; // 8 col w/o gutter

/// Main brand color
/// @type Color
$white: #ffffff !default;
$dark: #0b1528 !default;

$sunset1: #911d00 !default;
$sunset2: #a52d0f !default;
$sunset3: #ba3f20 !default;
$sunset4: #dd684a !default;
$sunset5: #e8866b !default;
$sunset6: #f2a28c !default;
$sunset7: #f9bfaf !default;
$sunset8: #fddcd3 !default;
$orangeneon: #ff896b !default;

$candy1: #71263b !default;
$candy2: #ac3258 !default;
$candy3: #cc3767 !default;
$candy4: #d7597b !default;
$candy5: #e17790 !default;
$candy6: #f1aebb !default;
$candy7: #f7c9d1 !default;
$candy8: #fce4e8 !default;
$candyneon: #ff6a84 !default;

$void1: #0f1741 !default;
$void2: #292a5f !default;
$void3: #42427c !default;
$void4: #5e5b9c !default;
$void5: #7c79ba !default;
$void6: #9d99cb !default;
$void7: #bdb9dd !default;
$void8: #dedced !default;
$voidneon: #8c86ff !default;

$tron1: #112240 !default;
$tron2: #2a4060 !default;
$tron3: #43587a !default;
$tron4: #5d7195 !default;
$tron5: #778bb0 !default;
$tron6: #93a7cd !default;
$tron7: #b3c3e2 !default;
$tron8: #ccd9f2 !default;
$tronneon: #5793ff !default;

$blu1: #005095 !default;
$blu2: #0764ab !default;
$blu3: #0b7dc8 !default;
$blu4: #0f95e2 !default;
$blu5: #71befe !default;
$blu6: #a1d0ff !default;
$blu7: #c9e3ff !default;
$blu8: #ecf3fc !default;
$bluneon: #13adfd !default;

$matrix1: #126b5c !default;
$matrix2: #298576 !default;
$matrix3: #52a496 !default;
$matrix4: #7dbdb2 !default;
$matrix5: #97c9c1 !default;
$matrix6: #b0d6d0 !default;
$matrix7: #c9e3df !default;
$matrix8: #e2f0ee !default;
$matrixneon: #56f6ce !default;

$tilebg: rgba($tron1, 0.5) !default;
$tilebg-hover: rgba($tron1, 0.8) !default;

/// Copy text color
/// @type Color
$text-color: $white !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
  "sm": (
    max-width: 400px,
  ),
  "md": (
    max-width: 800px,
  ),
  "lg": (
    max-width: 1160px,
  ),
) !default;

$breakpoint-values: (640px, 800px, 1160px);

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/assets/" !default;
