.button {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 18px 25px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    color: $white;
    text-shadow: 0px 0.698917px 43.6823px #AB9BE9;
    // background: linear-gradient(86.26deg, #8C86FF 3.09%, #AB5FB4 50.76%, #CC3767 100%);
    border-radius: 2px;
    border-top-left-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 0 rgba($voidneon, 0);
    transition: color 0.3s ease-out, box-shadow 0.3s ease-out;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 200%;
        height: 100%;
        background: linear-gradient(86.26deg, #8C86FF 1.5%, #AB5FB4 25%, #CC3767 50%, #AB5FB4 75%, #8C86FF 98.5%);
        z-index: 0;
        transition: left 0.3s ease-out;
    }

    &:hover {
        box-shadow: 0px 1px 10px rgba($voidneon, 0.5);

        &::before {
            left: -100%;
        }
    }

    span {
        position: relative;
        z-index: 3;
    }

    &.blank {
        background: none;
        border-radius: none;
        overflow: visible;
        
        &::before {
            background: none;
        }

        &:hover {
            box-shadow: none;
        }
    }

    & + .button {
        margin-left: 0.75rem;
    }

    &.signpost {
        display: inline-block;
        background: $white;
        box-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
        border-radius: 5px;

        &::before {
            display: none;
        }
    }

    &.frame {
        display: inline-block;
        background: none;
        border: 1px solid rgba(255, 255, 255, 0.2);

        &::before {
            display: none;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

.buttons-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    &.left {
        justify-content: flex-start;
    }

    .button {
        display: block;
    }
}