// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
  position: relative;
  z-index: 10;
  padding: 3rem 0;
  background-color: $tron1;
  &.bg-less {
    background-color: unset;
  }
  &.black {
    background-color: #0e1516;
  }

  .container {
    .top,
    .bottom {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .openSea {
      height: 35px;
    }

    .top {
      align-items: center;

      .right {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
      }

      .logo {
        img {
          width: 90px;
          height: 50px;
        }
      }

      .chunk {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: stretch;
        height: 35px;

        & > a {
          margin: 0 8px;
        }

        & + .chunk {
          padding-left: 10px;
          margin-left: 10px;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: $white;
            box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
            opacity: 0.2;
          }
        }
      }

      .social {
        a {
          position: relative;
          display: block;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;
          transform-origin: center;
          transition: transform 0.3s ease-out, filter 0.3s ease-out;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 200%;
            height: 100%;
            background: linear-gradient(86.26deg, #8c86ff 1.5%, #ab5fb4 25%, #cc3767 50%, #ab5fb4 75%, #8c86ff 98.5%);
            z-index: 0;
            transition: left 0.3s ease-out;
          }

          img {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
          }

          .notionIconWrapper {
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            .notionIcon {
              height: 19px;
              width: 19px;
            }
          }

          &:hover {
            // transform: scale(1.1);
            filter: drop-shadow(0px 1px 10px rgba(140, 134, 255, 0.5));

            &::before {
              left: -100%;
            }
          }
        }
      }

      .listed {
        position: relative;
        height: 35px;

        img {
          height: 35px;
          width: auto;
        }
      }

      .github {
        margin: 0;
      }

      .email {
        color: $void6;
        font-size: 13px;
        line-height: 1.1;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .bottom {
      margin-top: 2rem;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
      text-align: right;
      color: $void5;

      .links a {
        color: $void6;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .container {
      .top,
      .bottom {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .left,
        .right {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }
        .right {
          .chunk {
            &:nth-child(n) {
              margin: 2.5rem 0 0;
              padding: 0;

              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
