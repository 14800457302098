// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0.75rem 0;
  z-index: 450;
  transition: transform 0.4s ease, background-color 0.4s ease, box-shadow 0.4s ease;

  &.shrink {
    transform: translateY(-100%);
  }

  &.scrolled {
    background-color: $dark;
    box-shadow: 0 0 70px rgba(171, 155, 233, 0.25);

    &.shrink {
      box-shadow: 0 0 0 rgba(171, 155, 233, 0.25);
    }
  }

  .nav-open & {
    &.shrink {
      transform: translateY(0);
    }
  }

  .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    @media screen and (max-width: 1024px) {
      height: 42px;
    }
  }

  .logo {
    position: relative;
    left: -6px;
    display: inline-block;
    z-index: 5;
    margin: 12px 42px 10px 0;
    padding-top: 8px;

    img {
      width: 200px;
      height: auto;
    }

    svg {
      filter: drop-shadow(0px 0.418303px 26.1439px rgba(171, 155, 233, 0.5));
    }

    @media screen and (max-width: 1300px) {
      width: 168px;
      left: -20px;

      img {
        width: 185px;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    @media screen and (max-width: 1024px) {
      left: 0;
      padding-top: 0;
      max-width: 140px;
    }
  }

  nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    a {
      font-family: 'Outage';
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      color: $white;
      text-shadow: 0px 0.418303px 30px rgba(87, 147, 255, 0.2);
      transition: color 0.2s ease-out;

      &:first-child {
        margin-left: 25px;
      }

      &:hover,
      &.active {
        color: $voidneon;
      }
    }

    @media screen and (max-width: 1300px) {
      a {
        margin-left: 1.5rem;
        white-space: nowrap;

        &:first-child {
          margin-left: inherit;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 200px 0 120px;
      border-bottom-left-radius: 50px;
      background-color: $dark;
      box-shadow: 0 0 0 rgba(171, 155, 233, 0.25);
      z-index: 4;
      transform: translateY(-100%);
      transition: transform 0.4s ease-out, box-shadow 0.4s ease-out;
      min-height: 360px;

      a {
        margin: 0 0 40px;
      }

      .nav-open & {
        transform: translateY(0);
        box-shadow: 0 0 70px rgba(171, 155, 233, 0.25);
      }
    }
  }

  .links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-left: auto;
    align-items: center;
    z-index: 5;

    a {
      color: $white;
    }

    .social {
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      margin-left: 15px;
      border-radius: 50%;
      overflow: hidden;
      transform-origin: center;
      transition: transform 0.3s ease-out, filter 0.3s ease-out;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 200%;
        height: 100%;
        background: linear-gradient(86.26deg, #8c86ff 1.5%, #ab5fb4 25%, #cc3767 50%, #ab5fb4 75%, #8c86ff 98.5%);
        z-index: 0;
        transition: left 0.3s ease-out;
      }

      img {
        position: relative;
        z-index: 1;
      }

      &:hover {
        // transform: scale(1.1);
        box-shadow: 0px 1px 10px rgba($voidneon, 0.5);

        &::before {
          left: -100%;
        }
      }
    }

    .button {
      font-family: $text-font-stack;
    }

    .purchases {
      position: relative;
      margin-left: 2.5rem;

      &::before {
        content: '';
        position: absolute;
        left: -0.75rem;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: $white;
        box-shadow: 0px 0.871355px 8.71355px rgba($tron4, 0.5);
        opacity: 0.3;
      }

      @media screen and (max-width: 1300px) {
        padding: 0.75rem 1rem;
        margin-left: 2rem;

        &::before {
          left: -0.5rem;
        }
      }
    }

    .buynow {
      height: 42px;
      padding: 0.75rem 1rem;

      &:hover {
        color: $white;

        svg {
          transform: translate3d(0, 0, 0) rotate(120deg);

          path {
            transform: rotate(-120deg);
          }
        }
      }

      svg {
        transform: translate3d(0, 0, 0);
        transition: transform 0.4s ease-out;

        path {
          transform-box: fill-box;
          transform-origin: center;
          transition: transform 0.4s ease-out;
        }
      }
    }

    @media screen and (max-width: 1300px) {
      .social {
        width: 30px;
        height: 30px;
      }
    }

    @media screen and (max-width: 1024px) {
      margin: 0;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 160px;

      .social {
        margin: 0 10px 40px;
      }

      .purchases {
        flex: 0 1 100%;
        margin: 30px 0;

        &::before {
          left: 0;
          top: -1.5rem;
          width: 100%;
          height: 1px;
        }
      }

      .buynow {
        margin: 0;
      }
    }

    @media screen and (max-width: 640px) {
      .social {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .nav-toggle {
    display: none;
    position: absolute;
    right: 30px;
    top: 22px;
    width: 24px;
    height: 24px;
    // padding: 3px;
    z-index: 6;

    span {
      position: absolute;
      width: 100%;
      height: 3px;
      // background: $candy6;
      border-radius: 3px;
      transform-origin: center;
      transition: top 0.2s ease-out 0.2s;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $candy6;
        transition: width 0s linear 0.2s, transform 0.2s ease-out;
      }

      &:nth-child(1) {
        top: 2px;
        &:before {
          width: 20px;
        }
      }
      &:nth-child(2) {
        top: 11px;
        &:before {
          width: 24px;
        }
      }
      &:nth-child(3) {
        top: 20px;
        &:before {
          width: 12px;
        }
      }
    }

    .nav-open & {
      span {
        transition: top 0.2s ease-out;

        &:before {
          transition: width 0s linear 0.2s, transform 0.2s ease-out 0.2s;
          width: 24px;
        }

        &:nth-child(1) {
          top: 11px;
          &:before {
            transform: rotate(45deg);
          }
        }
        &:nth-child(2) {
          top: 11px;
          &:before {
            transform: scaleX(0);
          }
        }
        &:nth-child(3) {
          top: 11px;
          &:before {
            transform: rotate(-45deg);
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  .OpenSeaButtonWrapper {
    margin-right: 10px;
    @media screen and (max-width: 800px) {
      margin-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .openSea {
      height: 42px;
      @media screen and (max-width: 800px) {
        height: 60px;
      }
      min-width: 120px;
    }
  }

  .notionIconWrapper {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1300px) {
      height: 30px;
      width: 30px;
    }
    @media screen and (max-width: 1024px) {
      height: 50px;
      width: 50px;
    }
    .notionIcon {
      height: 25px;
      width: 25px;
      @media screen and (max-width: 1300px) {
        height: 17px;
        width: 17px;
      }
      @media screen and (max-width: 1024px) {
        height: 28px;
        width: 28px;
      }
    }
  }
}
