.page-home {
  background: $dark;
  max-width: 100vw;
  overflow: hidden;

  .bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    #bg0 {
      max-width: unset;
      width: 100%;
      height: 80%;
      object-fit: cover;
      object-position: right bottom;

      @media screen and (max-width: 480px) {
        width: 200%;
        height: 100vh;
        margin-left: -80%;
      }

      @media screen and (max-width: 400px) {
        margin-left: -20%;
        transform: scale(0.6);
        transform-origin: left bottom;
      }
    }

    #bg1 {
      position: absolute;
      min-width: 568px;
      min-height: 568px;
      width: 54.236vw;
      height: 54.236vw;
      left: 28.942vw;
      top: -21.667vw;

      background: radial-gradient(50% 50% at 50% 50%, #5e5b9c 0%, rgba(140, 134, 255, 0) 100%);
      opacity: 0.2;

      transform-origin: 40% 45%;
      animation: rotate 6s infinite forwards linear;
    }

    #bg2 {
      position: absolute;
      width: 39.951vw;
      height: 39.951vw;
      min-width: 418px;
      min-height: 418px;
      left: 53.599vw;
      top: -4.074vw;

      background: radial-gradient(50% 50% at 50% 50%, rgba(15, 149, 226, 0.8) 0%, rgba(15, 149, 226, 0) 100%);
      opacity: 0.31;

      transform-origin: 60% 45%;
      animation: rotate 8s infinite forwards linear;
    }

    #bg3 {
      position: absolute;
      width: 66.508vw;
      height: 66.508vw;
      min-width: 696px;
      min-height: 696px;
      left: 24.722vw;
      top: -14.91vw;

      background: radial-gradient(50% 50% at 50% 50%, rgba(204, 55, 103, 0.41) 0%, rgba(215, 89, 123, 0) 100%);
      opacity: 0.7;

      transform-origin: 52% 65%;
      animation: rotate 9s infinite reverse linear;
    }

    .grad {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 400px) {
      .grad {
        transform: translateX(-50%);
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .intro {
    padding: 200px 0;
    // background: url('../images/bg2.png') no-repeat fixed right top / contain #1c0a2d;
    // background-color: $dark;
    overflow: hidden;
    max-width: 100vw;
    height: 100vh;
    min-height: 720px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .container {
      position: relative;
    }

    .border {
      position: absolute;
      left: 70px;
      top: 0;
      height: 540px;
      width: 48px;

      path {
        stroke-dasharray: 540;
        stroke-dashoffset: -540;
        transition: stroke-dashoffset 2s ease-out;
      }

      @media screen and (max-width: 960px) {
        left: 20px;
      }

      @media screen and (min-width: 1600px) {
        left: 0;
      }
    }

    .content {
      max-width: unset;
      position: relative;
      padding-top: 100px;
      padding-left: 150px;

      @media screen and (min-width: 1600px) {
        padding-left: 80px;
      }

      .section-label {
        color: $voidneon;

        span {
          display: block;
          transition: transform 1s ease-out, opacity 1s ease-out;
          transform: translateY(-20px);
          opacity: 0;
        }
      }

      h1 {
        margin-bottom: 50px;
        font-family: 'Outage', sans-serif;
        font-size: 45px;
        font-weight: 700;
        line-height: 1;
        color: $white;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);

        span {
          display: block;
          transition: transform 1s ease-out 0.4s, opacity 1s ease-out 0.4s;
          transform: translateY(-30px);
          opacity: 0;
        }
      }

      .semi {
        position: relative;
        max-width: 480px;
        margin-bottom: 55px;
        padding-left: 25px;
        color: $white;
        opacity: 0.9;

        span {
          display: block;
          transition: transform 1s ease-out 0.9s, opacity 1s ease-out 0.9s;
          transform: translateY(-20px);
          opacity: 0;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: $white;
          opacity: 0.3;
          box-shadow: 0px 0.871355px 8.71355px rgba(140, 134, 255, 0.5);
          transition: transform 1s ease-out 0.9s;
          transform: scaleY(0);
          transform-origin: left top;
        }
      }

      .count {
        display: inline-block;

        img {
          transform: scale(0.85);
          opacity: 0;
          transition: transform 0.6s ease-out 1.6s, opacity 0.6s ease-out 1.6s;
          width: 360px;
        }
      }

      @media screen and (max-width: 960px) {
        padding-top: 30px;
        padding-left: 30px;
        margin-top: 0;

        .border {
          left: -10px;
        }

        h3 {
          margin-top: 5px;
          margin-bottom: 30px;
          font-size: 15px;
        }

        h1 {
          margin-bottom: 30px;
          font-size: 28px;
        }

        h2 {
          margin-left: 30px;
          margin-bottom: 45px;
          font-size: 17px;
        }

        .join {
          font-size: 15;
        }
      }

      @media screen and (max-width: 480px) {
        h1 br {
          display: none;
        }
      }
    }

    .tags {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      overflow: hidden;
      padding-bottom: 2rem;

      .border {
        position: relative;
        width: auto;
        left: auto;
        right: -9px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        height: auto;

        path {
          stroke-dasharray: 120;
          stroke-dashoffset: -120;
          transition: stroke-dashoffset 0.3s ease-out 2s;
        }
      }

      .links {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-end;

        a {
          display: block;
          font-family: 'IBM Plex Sans', sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.3;
          letter-spacing: 0.01em;
          color: transparent;
          transform: translateX(40px);
          opacity: 0;
          transition: transform 0.4s ease-out, opacity 0.4s ease-out;

          &:nth-child(1) {
            transition-delay: 2.4s;
          }
          &:nth-child(2) {
            transition-delay: 2.6s;
          }
          &:nth-child(3) {
            transition-delay: 2.8s;
          }
          &:nth-child(4) {
            transition-delay: 3s;
          }
          &:nth-child(5) {
            transition-delay: 3.2s;
          }

          span {
            position: relative;
            color: $candyneon;
            background: transparent;
            transition: color 0.4s ease-out;

            &::before {
              content: attr(title);
              position: absolute;
              left: 0;
              top: -1px;
              width: 100%;
              height: 100%;
              font-size: inherit;
              line-height: inherit;
              font-weight: inherit;
              letter-spacing: inherit;
              color: transparent;
              z-index: -1;
              background-clip: text;
              -webkit-background-clip: text;
              background-image: linear-gradient(269deg, #f1aebb 0.76%, #8c86ff 98.71%);
            }
          }

          &:hover {
            span {
              color: transparent;
            }
          }
        }
      }

      @media screen and (max-width: 1300px) {
        right: 30px;
      }

      @media screen and (max-width: 800px) {
        position: static;
        right: 0;
        margin-top: -40px;

        .links {
          a {
            font-size: 15px;
          }
        }
      }
    }

    .parade-wrapper {
      max-width: 100%;
      overflow: hidden;
    }

    .parade {
      position: absolute;
      left: 65%;
      top: 0;
      width: 130%;
      transform: translateX(100px);
      opacity: 0;
      transition: transform 0.3s ease-out, opacity 0.3s ease-out;
      transition-delay: 2s;
      pointer-events: none;

      @media screen and (min-width: 1600px) {
        transform: translateX(100px) scale(1.25);
        transform-origin: 25% center;
      }

      @media screen and (max-width: 1200px) {
        width: 285%;
      }

      img {
        position: relative;
        margin: 0 auto 5px;

        &.r1 {
          left: 0;
        }
        &.r2 {
          left: 99px;
        }
        &.r3 {
          left: 160px;
        }
        &.r4 {
          left: 60px;
        }
        &.r5 {
          left: -12px;
        }
        &.r6 {
          left: 50px;
        }
      }
    }

    .right {
      position: absolute;
      right: 0;
      top: 0;
      width: 30rem;
      height: auto;
      text-align: center;
      padding-top: 80px;
      // transform: translateX(100%);
      // opacity: 0;
      .voxo-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        font-weight: 600;
        letter-spacing: 0.01em;
        .highlight {
          color: #ff6a84;
        }
        p {
          font-weight: 400;
        }
      }

      h4 {
        color: $tron4;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
      }

      h3 {
        color: $white;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
      }

      .button {
        display: inline-block;
        margin-top: 2rem;
      }

      .points {
        position: relative;
        padding-left: 3rem;

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          opacity: 0.3;
          background-color: $white;
          box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
        }

        &::before {
          left: 0;
          top: 0;
          height: 100%;
        }

        &::after {
          left: 0;
          top: 100%;
          height: 40px;
          transform-origin: left top;
          transform: rotate(-45deg);
        }

        .point {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 3rem;

          img {
            width: 4rem;
            margin-right: 2rem;
          }

          p {
            color: $candy8;
          }

          .highlight {
            color: $candyneon;
          }
        }
      }

      .buttons-container {
        padding-left: 53px;
      }
    }

    @media screen and (max-width: 1200px) {
      height: auto;

      .right {
        position: relative;
        text-align: center;
        margin: auto;
        width: unset;
      }

      .parade-wrapper {
        margin-top: 40px;
        width: 100vw;
        max-width: unset;
        margin-left: -30px;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .parade {
        position: static;
      }

      .tags {
        display: block;
      }
    }

    @media screen and (max-width: 800px) {
      padding-bottom: 100px;

      .right {
        padding-top: 40px;
      }

      .tags {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: 640px) {
      padding: 120px 0;
      background-attachment: scroll;
      background-size: 250% auto;
      background-origin: right bottom;
    }

    &.play {
      .section-label,
      h1,
      .semi {
        span {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .semi {
        &::before {
          transform: scaleY(1);
        }
      }

      .tags {
        .links {
          a {
            transform: translateX(0);
            opacity: 0.9;
          }
        }
      }

      path {
        stroke-dashoffset: 0 !important;
      }

      .count {
        img {
          transform: scale(1);
          opacity: 1;
        }
      }

      .parade {
        transform: translateX(0);
        opacity: 1;

        @media screen and (min-width: 1600px) {
          transform: translateX(0) scale(1.25);
          transform-origin: 25% center;
        }
      }
    }
  }

  .viewer {
    background-color: $dark;
    // height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    iframe {
      width: clamp(600px, 50vw, $max-width);
      max-width: 100%;
      height: clamp(580px, 70vh, 80vh);
      border: 0 none;
      pointer-events: none;
      background-color: #0c182e;
      transition: opacity 0.3s ease-out;

      &.on {
        pointer-events: auto;
      }
    }

    .stats {
      position: absolute;
      left: calc((100% - #{$max-width}) / 2);
      top: 50%;
      transform: translateY(-50%);
      z-index: 8;

      &.with-banner {
        top: 50%;
        left: calc((100% - #{$max-width}) / 2 - 100px);
      }

      @media screen and (max-width: 1440px) {
        &.with-banner {
          top: 50%;
          left: calc((100% - #{$max-width}) / 2);
        }
      }

      @media screen and (max-width: 1300px) {
        &.with-banner {
          top: 50%;
          left: calc((100% - #{$max-width}) / 2 + 100px);
        }
      }

      @media screen and (max-width: 800px) {
        &.with-banner {
          top: calc(50% + 170px);
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 0;
        top: 25%;
        width: 1px;
        height: 50%;
        background-color: $white;
        box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
        opacity: 0.2;
      }

      .row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2px;
        white-space: nowrap;
      }

      span {
        display: inline-block;
        background-color: rgba($dark, 0.5);
      }

      .key {
        font-size: 11px;
        line-height: 1;
        font-weight: 800;
        letter-spacing: 0.1em;
        padding: 10px 10px 10px 12px;
        width: 100px;
        color: rgba($void7, 0.5);
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        text-transform: uppercase;
      }

      .value {
        padding: 10px 15px 8px 10px;
        font-family: 'Outage';
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
        color: #ffffff;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    .sibnav {
      position: absolute;
      right: calc((100% - #{$max-width}) / 2);
      top: 60px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;

      &.with-banner {
        top: 0;
      }

      @media screen and (min-width: 1600px) {
        right: calc((100% - #{$max-width}) / 4);
      }

      .prev,
      .next {
        margin-left: 10px;
        transform-origin: center;
        transition: transform 0.15s ease-out;

        img {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          object-fit: contain;
          object-position: center;
        }

        &:hover {
          transform: scale(1.05);
        }

        &.disabled {
          opacity: 0.2;
          pointer-events: none;
        }
      }
    }

    .togglebig {
      position: absolute;
      left: calc(50% - 50px);
      bottom: 100px;
      width: 100px;
      height: 100px;

      &::before {
        content: 'Tap to interact';
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        color: $void7;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.01em;
        white-space: nowrap;
        text-align: center;
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;
        opacity: 0.7;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        left: -150px;
        top: -150px;
        width: 400px;
        height: 400px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          #0b1528 0%,
          rgba(11, 21, 40, 0.489861) 55.43%,
          rgba(11, 21, 40, 0) 100%
        );
        z-index: 0;
      }

      img {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      @media screen and (max-width: 1010px) {
        bottom: 380px;
        //&.with-banner {
        //  top: calc(50% + 170px);
        //}
      }

      @media screen and (max-width: 600px) {
        bottom: auto;
        top: Max(calc(70vh - 200px), 380px);
        &.with-banner {
          top: 380px; // Milan D - Quick fix for small screens.
          //top: Max(calc(70vh - 200px), 380px);
        }
      }
    }

    .toggleinteract {
      position: absolute;
      // right: calc((100% - 600px) / 2);
      right: 25%;
      width: 40px;
      height: 40px;
      bottom: 0;
      margin: 40px;

      @media screen and (min-width: 2480px) {
        right: calc((100% - #{$max-width}) / 2);
      }

      &::before {
        content: 'Pause interactivity';
        position: absolute;
        right: calc(100% + 10px);
        top: 50%;
        transform: translateY(-50%) translateX(10px);
        padding: 7px 10px;
        border-radius: 2px;
        color: $white;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 0.01em;
        background-color: rgba($tron2, 0.5);
        white-space: nowrap;
        opacity: 0;
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;
      }

      &:hover {
        &::before {
          transform: translateY(-50%) translateX(0);
          opacity: 1;
        }
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        transition: opacity 0.15s ease-out;
      }

      .i {
        opacity: 0;
      }

      &.off {
        .p {
          opacity: 0;
        }
        .i {
          opacity: 1;
        }

        &::before {
          content: 'Interact';
        }
      }
    }

    .togglefs {
      position: absolute;
      right: calc((100% - #{$max-width}) / 2);
      bottom: 1rem;
      width: 88px;
      height: 88px;
      margin-right: -24px;
      z-index: 10;
      opacity: 0.7;
      transition: opacity 0.3s ease-out;

      @media screen and (min-width: 1600px) {
        right: calc((100% - #{$max-width}) / 4);
      }

      &::before {
        content: 'Click to \Ago full screen';
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-60%);
        font-size: 12px;
        text-align: right;
        white-space: pre;
        color: rgba($void7, 1);
      }

      img {
        display: block;
      }

      &:hover {
        opacity: 1;
      }
    }

    @media screen and (max-width: 1300px) {
      .stats {
        left: 130px;
      }

      .sibnav {
        right: 30px;
      }

      .togglefs {
        right: 30px;
      }
    }

    @media screen and (max-width: 1160px) {
      .toggleinteract {
        right: calc((100% - 580px) / 2);
      }
    }

    @media screen and (max-width: 1010px) {

      .stats {
        align-self: flex-start;
        position: static;
        margin-left: 50px;
        &.with-banner {
          top: calc(50% + 255px);
          margin-top: 80px;
        }
      }

      .sibnav {
        top: Max(620px, calc(70vh + 40px));
        &.with-banner {
          top: calc(50% + 255px);
        }
      }

      .toggleinteract {
        top: Max(460px, calc(70vh - 120px));
        bottom: auto;
        right: 0;
      }

      .togglefs {
        display: none;
      }
    }

    @media screen and (max-width: 800px) {
      .stats {
        left: 0;
        top: auto;
        bottom: 30px;
        transform: none;
        margin-left: 50px;
      }

      .sibnav {
        .prev,
        .next {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .toggle {
        bottom: 30px;
        right: 10px;
      }
    }

    @media screen and (max-width: 600px) {
      align-items: flex-start;

      .stats {
        position: static;
        margin-top: 40px;
      }

      .sibnav {
        top: Max(620px, calc(70vh + 40px));
        &.with-banner {
          top: calc(50% + 255px);
        }
      }

      .toggleinteract {
        top: Max(460px, calc(70vh - 120px));
        bottom: auto;
        right: 0;
      }

      .togglefs {
        display: none;
      }
    }

    @media screen and (max-width: 480px) {
      .stats {
        transform-origin: left bottom;
        transform: scale(0.9);
      }
    }
  }

  .glance {
    padding: 150px 0 175px;
    background-color: $dark;

    sup {
      color: $void7;
    }

    .content {
      width: 100%;
      max-width: $content-width;
      margin: 0 auto;
    }

    .section-label {
      margin-bottom: 3rem;
      text-align: center;
      color: $candy5;
      text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.5);
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
    }

    .grid {
      gap: 48px 72px;
    }

    .cell {
      text-align: center;
      transform: scale(1.1);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;

      &:nth-child(1) {
        transition-delay: 0.2s;
      }
      &:nth-child(2) {
        transition-delay: 0.4s;
      }
      &:nth-child(3) {
        transition-delay: 0.6s;
      }
      &:nth-child(4) {
        transition-delay: 0.8s;
      }
      &:nth-child(5) {
        transition-delay: 1s;
      }
      &:nth-child(6) {
        transition-delay: 1.2s;
      }
    }

    .graphic {
      margin-bottom: 2rem;

      img {
        height: 100px;
        width: 100px;
        margin: 0 auto;
      }
    }

    p {
      &.semi {
        color: $white;
      }

      &.xs {
        color: $candy8;
        opacity: 0.5;
      }

      &.footnote {
        color: $candy8;
        opacity: 0.3;
      }
    }

    .highlight {
      color: $candyneon;
    }

    @media screen and (max-width: 800px) {
      padding: 100px 0;
    }

    @media screen and (max-width: 640px) {
      .grid {
        gap: 80px 40px;
        grid-template-columns: 1fr 1fr;
      }
    }

    @media screen and (max-width: 480px) {
      .grid {
        display: block;
      }

      .cell {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.play {
      .section-label {
        transform: translateY(0);
        opacity: 1;
      }

      .cell {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .presale {
    padding: 100px 0;
    text-align: center;
    background: url('../../images/bus-stop.png') no-repeat left center / cover #1c0e2d;

    .container {
      overflow: hidden;
      .progress-description__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .progress-description {
          max-width: 459px;
          padding: 0;
        }
      }
    }

    .section-label {
      margin-bottom: 20px;
      color: $voidneon;
      text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.5);
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      transition-delay: 0.2s;

      sup {
        color: $void7;
      }
    }

    h2 {
      color: $white;
      text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
      margin-bottom: 60px;
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
    }

    p {
      padding: 0 3rem;
      color: $tron8;
      text-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      transition-delay: 0.4s;

      &.sm {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 13px;
        line-height: 1.3;
        letter-spacing: 0.01em;
        color: $void6;
        text-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
      }

      .sym {
        flex: 0 0 1rem;
        width: 1rem;
        color: $void7;
      }
    }

    .signpost {
      min-width: 170px;
      padding: 14px 15px;
      margin: 40px 5px;
      font-size: 15px;
      line-height: 18px;
      color: $tron3;
      transform: scale(1.1);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      cursor: default;
      // transition-delay: 0.6s;

      .rem,
      .sold {
        font-family: 'Outage';
        font-size: 32px;
        line-height: 1.2;
        color: $void1;
      }

      .price {
        color: $candyneon;
      }

      @media screen and (max-width: 480px) {
        width: 200px;

        & + .signpost {
          margin-top: -35px;
        }
      }
    }

    .progress-viewport {
      position: relative;
      overflow-x: hidden;
      overflow-y: visible;
      margin-bottom: 2rem;

      @media screen and (max-width: 640px) {
        overflow-x: scroll;
      }
    }

    .progress {
      position: relative;
      display: grid;
      grid-template-columns: repeat(13, 1fr);
      gap: 0;
      min-width: $max-width;
      padding: 30px 10px 10px;
      opacity: 0;
      transition: opacity 0.3s ease-out 0.8s;

      &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: calc(55% + 8px);
        width: calc(100% - 20px);
        height: 2px;
        border-top: 2px dotted $white;
        box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
        opacity: 0.3;

        opacity: 0;
        transform: scaleX(0);
        transform-origin: left center;
        transition: transform 1s ease-out, opacity 0.5s ease-out;
        transition-delay: 1s;
      }

      &::after {
        content: '';
        position: absolute;
        right: 10px;
        top: 70px;
        width: 2px;
        height: 34px;
        background-color: $voidneon;
        box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
        opacity: 0;
        transition: opacity 0.3s ease-out;
        transition-delay: 1.8s;
      }

      .block {
        position: relative;
        text-align: center;
        padding: 18px 0 10px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 40px;
          width: 1px;
          height: 34px;
          background-color: $white;
          box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
          opacity: 0.5;
        }

        &.full {
          grid-column: span 2;
        }

        &.half {
          grid-column: span 1;
        }

        &.active {
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: -30px;
            width: 68px;
            height: 68px;
            transform: translateX(-50%) translateY(-20px);
            background: url("data:image/svg+xml,%3Csvg width='67' height='68' viewBox='0 0 67 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M24.4916 32.7047L30.1771 37.924C30.3141 38.0731 30.5196 38.1477 30.7251 38.1477C30.7936 38.1477 30.8621 38.1477 30.9306 38.1477L38.3285 36.4328C38.671 36.4328 39.0135 36.1345 39.1505 35.7617V35.6872L41 27.56C41 27.4854 41 27.4109 41 27.3363V27.2617C41 27.1872 41 27.1872 41 27.1126V27.0381C41 26.9635 40.9315 26.9635 40.9315 26.8889L40.863 26.8144C40.863 26.8144 40.863 26.7398 40.7945 26.7398C40.7945 26.7398 40.7945 26.7398 40.726 26.6653L34.7665 21.3714C34.561 21.2223 34.3555 21.1477 34.0815 21.1477L26.5466 22.7881C26.2041 22.8626 25.9986 23.0863 25.9301 23.4591L24.0806 31.6609C23.8751 32.1082 24.0806 32.5556 24.4916 32.7047ZM37.575 34.7179L31.8211 36.06L33.2596 29.8714L36.6845 29.1258L39.0135 28.6038L37.575 34.7179ZM33.9445 23.0117L38.26 26.8144L32.7801 28.0819L28.4646 24.2047L33.9445 23.0117ZM27.1631 25.2486L31.6841 29.3495L30.2456 35.5381L25.7246 31.4372L27.1631 25.2486Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0.147705' width='67' height='67' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='1' dy='4'/%3E%3CfeGaussianBlur stdDeviation='12.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 0.416117 0 0 0 0 0.517662 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")
              no-repeat center / contain transparent;

            opacity: 0;
            transition: transform 0.4s ease-out, opacity 0.4s ease-out;
            transition-delay: inherit;
          }
        }

        &:first-child {
          &::before {
            width: 2px;
            background-color: $candyneon;
            box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
          }
        }

        &:nth-child(1) {
          transition-delay: 1s;
        }
        &:nth-child(2) {
          transition-delay: 1.14s;
        }
        &:nth-child(3) {
          transition-delay: 1.28s;
        }
        &:nth-child(4) {
          transition-delay: 1.42s;
        }
        &:nth-child(5) {
          transition-delay: 1.56s;
        }
        &:nth-child(6) {
          transition-delay: 1.7s;
        }
        &:nth-child(7) {
          transition-delay: 1.84s;
        }
      }

      .count {
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.2;
        color: $white;
        text-shadow: 0px 1px 4px rgba(113, 19, 253, 0.5);

        opacity: 0;
        transform: translateY(-20px);
        transition: transform 0.5s ease-out, opacity 0.5s ease-out;
        transition-delay: inherit;

        svg {
          width: 18px;
          height: 18px;
          vertical-align: bottom;
        }
      }

      .cost {
        font-size: 15px;
        font-weight: 700;
        line-height: 1.2;
        color: $voidneon;
        text-shadow: 0px 1px 4px rgba(255, 106, 132, 0.25);

        opacity: 0;
        transform: translateY(20px);
        transition: transform 0.5s ease-out, opacity 0.5s ease-out;
        transition-delay: inherit;
      }

      .progressbar {
        position: absolute;
        left: 0;
        top: 61px;
        width: 0;
        height: auto;
        overflow-x: hidden;
        overflow-y: visible;
        transition: width 1s ease-out;
      }
    }

    p.sm {
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      transition-delay: 2.4s;

      svg {
        display: inline-block;
        vertical-align: bottom;
      }
    }

    .buttons-container {
      margin-top: 60px;
      transform: scale(1.1);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      transition-delay: 2.6s;
    }

    @media screen and (max-width: 800px) {
      p {
        padding: 0;
      }
    }

    @media screen and (max-width: 640px) {
      background-position: right center;
    }

    &.play {
      h2,
      p {
        transform: translateY(0);
        opacity: 1;
      }

      .progress {
        opacity: 1;

        &::before {
          opacity: 0.3;
          transform: scaleX(1);
        }

        &::after {
          opacity: 1;
        }

        .block {
          .count,
          .cost {
            opacity: 1;
            transform: translateY(0);
          }

          &::after {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
          }
        }
      }

      .buttons-container {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  #stars {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $dark;
    z-index: 1;
    // filter: blur(1px);
  }

  .world {
    position: relative;
    padding: 150px 0 0;
    text-align: center;

    .section-label {
      color: $candy5;
      text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.5);
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
    }

    h2 {
      color: $white;
      text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      transition-delay: 0.3s;
    }

    p {
      color: $tron8;
      text-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
      transform: translateY(-10px);
      opacity: 0;
      transition: transform 0.4s ease-out, opacity 0.4s ease-out;
      transition-delay: 0.6s;
    }

    @media screen and (max-width: 800px) {
      padding: 100px 0;
    }

    &.play {
      h2,
      p {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .chars {
    padding: 150px 0;

    .tabs-viewport {
      width: 100%;
      margin-bottom: 4rem;
    }

    .tabs {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      width: fit-content;

      a {
        position: relative;
        padding: 35px 40px 14px 0;
        margin-right: 40px;
        font-family: Outage;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.1;
        color: $void4;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
        transition: color 0.3s ease-out;

        &::before,
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0.3;
          background-color: $white;
          transition: transform 0.3s ease-out;
        }

        &::before {
          width: 100%;
          height: 1px;
          box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
          transform: scaleX(0);
          transform-origin: right center;
        }

        &::after {
          width: 1px;
          height: 100%;
          box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
          transform-origin: center top;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        &:hover,
        &.active {
          color: $white;
        }

        &.active {
          &::before {
            transform: scaleX(1) scaleY(1);
          }
        }
      }
    }

    .tabs-container {
      position: relative;
      min-height: 881px;

      .tab-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding-left: 5rem;

        pointer-events: none;
        z-index: -1;
        opacity: 0;
        transform: translateY(40px);
        transition: opacity 0.4s ease-out, transform 0.4s ease-out;

        &::before,
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 20px;
          background-color: $white;
          box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
          opacity: 0.3;
        }

        &::before {
          width: 1px;
          height: 540px;
        }

        &::after {
          width: 40px;
          height: 1px;
          top: 560px;
          transform-origin: left top;
          transform: rotate(45deg);
        }

        &.active {
          display: block;
          position: static;
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
          z-index: 5;
        }
        .archetype-tab {
          text-transform: capitalize;
        }
        .archetypes-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 80px;
          row-gap: 70px;

          .archetype {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .archetype-image {
              width: 180px;
              height: 180px;
              margin-right: 35px;
              @media screen and (max-width: 960px) {
                margin-right: 0px;
                margin-bottom: 16px;
              }
            }

            .archetype-text {
              display: flex;
              flex-direction: column;

              .archetype-header {
                color: $candyneon;
                font-family: 'Outage';
                font-size: 20px;
                line-height: 22px;
                font-weight: 700;
                margin: 0 0 6px 0;
              }
              .archetype-fraction {
                font-size: 16px;
                color: $white;
                font-weight: 700;
                margin-bottom: 20px;
                span {
                  font-weight: 400;
                }
              }
              .archetype-legend {
                font-size: 14px;
                font-weight: 500;
                color: $tron6;
                line-height: 20px;
                min-width: 300px;
                pre {
                  margin: 0 0 1.2em;
                  font-size: inherit;
                  font-family: inherit;
                }
                .italic {
                  font-style: italic;
                }
              }
            }
          }
        }
        @media screen and (max-width: 960px) {
          .archetypes-grid {
            grid-template-columns: repeat(1, 1fr);

            .archetype {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
          }
        }
      }
    }

    .switch {
      position: relative;
      left: -5rem;
      width: calc(100% + 5rem);
      padding-left: 5rem;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 20px;
        background-color: $white;
        box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
        opacity: 0.3;
        width: 50px;
        height: 1px;
      }

      a {
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 18px 25px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        color: $white;
        background-color: rgba(124, 121, 186, 0.2);
        border-radius: 2px;
        transition: background-color 0.4s ease-out;
        font-family: 'IBM Plex Sans', sans-serif;

        > span {
          font-weight: 400;
          text-transform: capitalize;
        }

        &:hover {
          background-color: rgba(124, 121, 186, 0.5);
        }

        &.active {
          background-color: $candyneon;
          &.angos {
            background-color: #ff6a84;
          }
          &.astras {
            background-color: #13adfd;
          }
          &.corpo {
            background-color: #dd684a;
          }
          &.verita {
            background-color: #52a496;
          }
        }

        &:last-child {
          &::after {
            content: '';
            position: absolute;
            left: calc(100% + 40px);
            top: calc(50% - 1px);
            width: 100vw;
            height: 1px;
            background-color: $white;
            opacity: 0.3;
            box-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
          }
        }
      }
    }

    .inner {
      // display: none;
      position: absolute;
      left: 0;
      top: 70px;
      width: 100%;
      padding: 100px 0 0 6.5rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      opacity: 0;
      transform: translateX(-40px);
      transition: opacity 0.4s ease-out, transform 0.4s ease-out;
      pointer-events: auto;
      z-index: -1;

      &.active {
        display: flex;
        position: static;
        width: 100%;
        padding-left: 1.5rem;
        opacity: 1;
        transform: translateX(0);
        z-index: 10;
      }

      .desc {
        width: 30rem;
        flex: 0 0 30rem;
        .factions-description {
          font-family: 'IBM Plex Sans', sans-serif;
          font-weight: 400;
          font-size: 18px;
          opacity: 0.9;
          &.angos {
            font-size: 16px;
          }
        }

        .subtitle {
          font-family: 'IBM Plex Sans', sans-serif;
          font-style: italic;
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          margin: 15px 0 20px 0;
          opacity: 0.9;
        }
        .characteristics {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;
          .head {
            font-family: 'Outage';
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 0.07em;
            color: #ffffff;
            text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
          }
          ul {
            padding: 20px;
            > li {
              margin-bottom: 10px;
              padding-left: 10px;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 140%;
              color: #b3c3e2;
              opacity: 0.9;
              &::marker {
                font-size: 20px;
              }
              &.angos {
                &::marker {
                  color: #ff6a84;
                }
              }
              &.astras {
                &::marker {
                  color: #13adfd;
                }
              }
              &.corpo {
                &::marker {
                  color: #dd684a;
                }
              }
              &.verita {
                &::marker {
                  color: #52a496;
                }
              }
            }
          }
        }
      }

      .viewer {
        display: block;
        position: relative;
        width: 32.5rem;
        height: auto;
        flex: 0 0 32.5rem;
        padding: 0;
      }

      .title {
        margin-bottom: 0;
        font-family: 'Outage';
        font-size: 30px;
        line-height: 1.1;
        color: $candyneon;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
        &.angos {
          color: #ff6a84;
          text-shadow: none;
        }
        &.astras {
          color: #13adfd;
          text-shadow: none;
        }
        &.corpo {
          color: #dd684a;
          text-shadow: none;
        }
        &.verita {
          color: #52a496;
          text-shadow: none;
        }
      }

      .sn {
        margin-bottom: 20px;
        line-height: 1.3;
        letter-spacing: 0.01em;
        color: $tron3;

        span {
          font-weight: 700;
          color: $tron6;
        }
      }

      .features {
        margin: 50px 0 1.5rem;
        color: $white;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
      }

      ul {
        margin: 0;
        padding: 0 0 0 50px;
        list-style: disc outside none;
      }

      p,
      li {
        color: $tron7;
      }

      li {
        margin-bottom: 30px;
        padding-left: 50px;

        &::marker {
          color: $candyneon;
        }

        &:last-child {
          margin: 0;
        }
      }

      .viewport {
        width: 520px;
        height: 520px;
        margin-top: 68px;
        border-top-left-radius: 30px;
        background-color: #1d1733;
        overflow: hidden;

        iframe {
          display: block;
          width: 100%;
          height: 100%;
          margin: 0;
          pointer-events: auto;
        }
      }

      .stats {
        position: absolute;
        left: 0;
        top: 400px;
        transform: none;

        &::before {
          display: none;
        }
      }

      .sibnav {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;

        .prev,
        .next {
          margin-left: 10px;

          svg {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .gears {
        width: 100%;
        padding: 16px 16px 20px;
        margin-top: 10px;
        border: 1px solid rgba($white, 0.3);
        border-top: 0 none;
        filter: drop-shadow(0px 1px 10px rgba(140, 134, 255, 0.5));
        border-radius: 2px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .slot {
          flex: 0 0 25%;
          padding: 0 10px;
          font-size: 16px;
          line-height: 1.3;
          text-align: center;
          border-right: 1px solid #2a4060;
          color: $void8;

          &:last-child {
            border: 0 none;
          }

          .label {
            font-size: 11px;
            font-weight: 800;
            line-height: 1;
            color: rgba($void7, 0.5);
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 1300px) {
      .container {
        overflow: hidden;
      }

      .inner {
        .desc {
          flex: 0 1 calc(100% - 520px);
          padding-right: 2rem;
        }

        .viewer {
          flex: 0 0 520px;
        }
      }
    }

    @media screen and (max-width: 960px) {
      .tabs-container {
        .tab-content {
          padding-left: 0;

          &::before,
          &::after {
            display: none;
          }
        }

        .switch {
          &::before {
            display: none;
          }

          a {
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }

      .inner {
        padding-left: 0;

        &.active {
          padding-left: 0;
        }

        .desc {
          flex-basis: calc(100% - 400px);
        }

        .viewer {
          flex-basis: 400px;
        }

        .stats {
          top: 320px;
        }

        .viewport {
          width: 400px;
          height: 400px;
        }

        .gears {
          padding: 0.5rem 0.25rem 1rem;

          .slot {
            flex-shrink: 1;
            font-size: 11px;

            .label {
              font-size: 9px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
      .tabs-viewport {
        margin-bottom: 3rem;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .tabs {
        a {
          padding: 1rem 20px 1rem 0;
          font-size: 22px;
        }
      }

      .switch {
        a {
          padding: 0.75rem 1rem;
          font-size: 14px;
        }
      }

      .inner {
        top: 3rem;
        padding-top: 60px;
      }
    }

    @media screen and (max-width: 640px) {
      .inner {
        flex-direction: column;

        ul {
          padding-left: 1.25rem;

          li {
            padding-left: 1.25rem;
          }
        }

        .desc,
        .viewer {
          flex-basis: 100%;
          width: 100%;
        }

        .desc {
          margin-bottom: 60px;
        }

        .viewport {
          width: 100%;
        }

        .stats {
          top: 280px;

          .key {
            display: none;
          }
        }
      }
    }
  }

  .how {
    padding: 150px 0 100px;
    background: linear-gradient(180deg, #0e1c34 0%, $dark 300px, $dark 100%);
    &#how-it-works {
      padding-bottom: 0;
    }

    h2 {
      margin-bottom: 60px;
      text-align: center;
      color: $white;
      text-shadow: 0px 4px 20px rgba(87, 147, 255, 0.59);
    }

    .tiles {
      & > .split {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .left,
        .right {
          flex: 0 0 calc((100% - 20px) / 2);
          width: calc((100% - 20px) / 2);

          .tile {
            width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }

    .tile {
      flex: 0 0 calc((100% - 20px) / 2);
      width: calc((100% - 20px) / 2);
      margin-bottom: 20px;
      background-color: $tilebg;
      border-radius: 10px;
      padding: 80px 60px 100px;

      h3 {
        color: $candyneon;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
      }

      p {
        color: $tron7;
      }

      figure {
        margin: 60px 0 40px;
      }

      &.full {
        flex-basis: 100%;
        width: 100%;
        padding: 80px 100px 100px;

        h3 {
          color: $white;
          text-align: center;
        }

        .section-label {
          color: $candy5;
          text-align: center;
          text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.5);
          text-transform: uppercase;
        }
      }

      & > .split {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: 75px;

        .left,
        .right {
          flex: 0 0 50%;
          width: 50%;
        }

        .left {
          padding-right: 75px;
        }
      }

      &.royalties {
        color: $tron7;

        .right {
          .section-label {
            margin-bottom: 15px;
            text-align: left;
            color: $white;
            text-transform: uppercase;
          }
        }

        .section-label.sm {
          color: $white;
          text-align: left;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          border-top: 1px solid rgba($white, 0.2);

          thead {
            th {
              padding: 27px 8px 20px 0;
              text-align: left;
              color: $white;
              font-size: 11px;
              font-weight: 800;
              line-height: 1;
              letter-spacing: 0.1em;

              span {
                font-size: 15px;
                line-height: 1.3;
                color: $candy5;
              }
            }
          }

          tbody {
            border-top: 1px solid rgba($white, 0.2);

            tr {
              td {
                background-color: rgba($tron2, 0.3);
                color: $white;
                padding: 7px 0 11px 0;
                border-bottom: 1px solid $dark;
                transition: color 0.3s ease-out, background-color 0.3s ease-out;

                &:first-child {
                  padding-left: 30px;
                }
              }

              &:hover {
                td {
                  background-color: rgba($tron2, 0.8);
                }
              }
            }
          }

          &.dim {
            tbody {
              tr {
                td {
                  &:nth-child(3),
                  &:nth-child(4) {
                    color: rgba($white, 0.3);
                  }
                }
              }
            }
          }
        }

        .years {
          margin-top: 30px;

          .frame {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: 8px;
            font-size: 11px;
            font-weight: 800;
            line-height: 1;
            color: $white;
            text-shadow: 0px 1px 4px rgba(113, 19, 253, 0.5);

            span {
              position: relative;
              top: -3px;
              display: inline-block;
              width: 43px;
              margin-right: 6px;
              font-size: 21px;
              text-align: center;
              line-height: 1.2;
              letter-spacing: 0.1em;
              color: $candyneon;
              text-shadow: 0px 1px 4px rgba(113, 19, 253, 0.5);
              vertical-align: middle;
            }

            a {
              position: relative;
              padding: 5px;
              width: 25px;
              height: 25px;

              &::before,
              &::after {
                content: '';
                position: absolute;
                background-color: $voidneon;
                box-shadow: 0px 0.418303px 30px rgba(87, 147, 255, 0.2);
                transition: background-color 0.2s ease-out, opacity 0.2s ease-out;
              }

              &:hover {
                &::before,
                &::after {
                  background-color: $white;
                }
              }

              &.disabled {
                pointer-events: none;
                opacity: 0.3;
              }

              &.minus {
                margin-right: 2rem;

                &::before {
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 10px;
                  height: 2px;
                }
              }

              &.plus {
                margin-left: 2rem;

                &::before {
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 10px;
                  height: 2px;
                }

                &::after {
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 2px;
                  height: 10px;
                }
              }
            }
          }
        }

        .highlight {
          color: $white;
        }

        .sm {
          margin: 25px 0 30px;
          font-size: 13px;
          line-height: 1.2;
          color: $tron5;
          letter-spacing: 0.01em;
        }

        .xs {
          color: $tron6;
        }
      }

      &.prize {
        color: $tron8;

        .split {
          margin-top: 0;
          align-items: center;
        }

        .right {
          .sm {
            color: $tron4;
            font-size: 13px;
            line-height: 1.2;
            letter-spacing: 0.01em;
          }
        }

        .highlight {
          color: $candyneon;
        }
      }

      &.jazzy {
        strong {
          font-weight: 700;
          color: $white;
        }
      }

      &.stats {
        color: $void7;

        h3 {
          color: $candyneon;
        }

        .section-label {
          margin-top: 80px;
          color: $white;
          text-align: left;
          text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
        }

        ul {
          padding-left: 1.5rem;

          li {
            @extend h4;
            padding-left: 1.5rem;
            margin-bottom: 20px;
            color: inherit;
          }
        }

        .highlight {
          color: $candy5;
        }

        figure {
          img {
            width: 100%;
          }
        }

        .tag {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;

          span {
            padding: 14px 25px;
            display: inline-block;
            font-weight: 700;
            background-color: rgba($void5, 0.1);

            &:first-child {
              padding-right: 10px;
            }
            &:last-child {
              padding-left: 10px;
            }
          }
        }

        .loadout-list {
          margin-top: 40px;
        }

        .loadout-bar {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 15px;

          .label {
            width: 140px;
          }

          .bar {
            height: 12px;
            background-color: rgba($void5, 0.3);
          }

          .highlight {
            margin-left: 1.5rem;
          }
        }
      }

      &.roadmap {
        .highlight {
          margin-right: 30px;
          color: $candy5;
        }

        table {
          td {
            vertical-align: top;

            &:first-child {
              width: 120px;
            }
          }

          p {
            margin-bottom: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .tile {
        &.full {
          padding: 80px 60px;
        }

        & > .split {
          .left {
            padding-right: 3rem;
          }
        }

        &.royalties {
          table {
            tbody {
              td {
                &:first-child {
                  padding-left: 1rem;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
      .tile {
        padding: 60px 40px;

        &.full {
          padding: 60px 40px;
        }
      }
    }

    @media screen and (max-width: 640px) {
      .container {
        padding-left: 10px;
        padding-right: 10px;
      }

      .tiles {
        & > .split {
          display: block;

          .left,
          .right {
            width: 100%;
          }
        }
      }

      .tile {
        padding-left: 30px;
        padding-right: 30px;

        & > .split {
          display: block;

          .left,
          .right {
            width: 100%;
            padding: 0;
          }

          .left {
            margin-bottom: 60px;

            .buttons-container {
              justify-content: center;
            }
          }
        }

        &.stats {
          figure {
            padding: 0 !important;

            img {
              max-width: unset;
            }
          }

          .loadout-bar {
            .label {
              width: 100px;
            }

            .bar {
              max-width: calc(100% - 140px) !important;
            }
          }
        }

        &.prize {
          .split {
            .left {
              img {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }

  .team {
    padding: 150px 0 100px;
    background: linear-gradient(180deg, #0e1c34 0%, $dark 300px, $dark 100%);

    h2 {
      margin-bottom: 80px;
      text-align: center;
      color: $white;
      text-shadow: 0px 4px 20px rgba(87, 147, 255, 0.59);
    }

    .team-list {
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
      gap: 60px 25px;
    }

    .team-member {
      border-radius: 5px;
      box-shadow: 0px 1px 10px rgba(140, 134, 255, 0);
      transition: box-shadow 0.3s ease-out;
      display: flex;
      flex-flow: column;
      justify-content: center;
      cursor: pointer;

      .desc {
        flex: 1 0 auto;
        padding: 30px 20px 40px;
        background-color: #0e1c34;
        transition: background-color 0.3s ease-out;
      }

      .portrait {
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
          transform-origin: center;
          transition: transform 0.3s ease-out;
        }
      }

      .name {
        margin-bottom: 5px;
        font-family: 'Outage';
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.07em;
        color: $white;
        text-shadow: 0px 4px 25px rgba(215, 89, 123, 0.4);
      }

      .title {
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: 0.01em;
        font-weight: 400;
        color: $tron6;
        text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.5);
      }

      &:hover {
        box-shadow: 0px 0.418303px 40px rgba(171, 155, 233, 0.25);

        .portrait {
          img {
            transform: scale(1.1);
          }
        }

        .desc {
          background-color: $tilebg-hover;
        }
      }
    }

    .working-team {
      margin-top: 100px;
      text-align: center;

      p {
        color: $tron8;
        text-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
      }

      .section-label {
        margin-bottom: 40px;
        color: $candy5;
        text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.5);
      }

      .heading {
        color: $white;
        text-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
      }
    }

    @media screen and (max-width: 960px) {
      padding: 80px 0 60px;

      h2 {
        margin-bottom: 60px;
      }

      .team-list {
        grid-template-columns: repeat(4, 1fr);
        gap: 20px 16px;
      }

      .team-member {
        .desc {
          padding: 20px 12px 25px;
        }

        .name {
          font-size: 12px;
        }

        .title {
          font-size: 11px;
        }
      }
    }

    @media screen and (max-width: 640px) {
      .team-list {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    @media screen and (max-width: 480px) {
      .team-list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .team-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($dark, 0.9);
    z-index: -10;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease-out;

    .close {
      position: absolute;
      right: 100px;
      top: 55px;
      width: 89px;
      height: 89px;

      @media screen and (max-width: 1024px) {
        top: 50px;
        right: 20px;
      }

      @media screen and (max-width: 540px) {
        top: auto;
        bottom: -3px;
        right: 0;
        z-index: 15;
      }
    }

    .cards-viewport {
      width: 100%;
      overflow: visible;
    }

    .cards {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: auto;
      transform: scale(0.8);
      opacity: 0;
      padding-left: calc((100% - 450px - 90px) / 2);
      padding-right: calc((100% - 450px - 90px) / 2);

      transition: opacity 0.4s ease-out, transform 0.4s ease-out;
      transition-delay: 0.2s;

      .card {
        position: relative;
        flex: 0 0 450px;
        margin: 0 45px;
        width: 100%;
        max-width: 450px;
        height: 580px;
        padding: 40px 50px 80px;
        color: rgba($tron7, 0.9);
        background-color: $dark;
        box-shadow: 2px 5px 61px rgba(225, 119, 144, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease-out;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($dark, 0.7);
          z-index: 50;
          pointer-events: auto;
          opacity: 1;
          transition: opacity 0.3s ease-out;
        }

        &.active {
          transform: translateY(-45px);

          &::after {
            opacity: 0;
            pointer-events: none;
          }

          .portrait {
            &::after {
              opacity: 0;
            }
          }
        }

        .top {
          position: relative;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-end;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='176' height='49' viewBox='0 0 176 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.2' filter='url(%23filter0_d)'%3E%3Cpath d='M165 10.0868L138.087 37H10' stroke='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0.733276' width='175.354' height='47.7667' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.548228 0 0 0 0 0.524451 0 0 0 0 1 0 0 0 0.5 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: contain;
            width: 155px;
            height: 45px;
          }
        }

        .id {
          flex: 0 0 200px;
        }

        .portrait {
          position: relative;
          margin-right: -70px;
          border-radius: 5px;
          overflow: hidden;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 100%;
            background-color: rgba($dark, 0.7);
            transition: opacity 0.3s ease-out;
          }
        }

        .name {
          margin-bottom: 10px;
          font-family: 'Outage';
          font-size: 22px;
          font-weight: 700;
          line-height: 1.1;
          color: $white;
          text-shadow: 0px 1px 10px rgba(140, 134, 255, 0.5);
        }

        .title {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: 0.01em;
          color: $tron6;
          text-shadow: 1px 2px 15px rgba(124, 121, 186, 0.5);
          margin-bottom: 40px;
        }

        .bio {
          margin-top: 40px;
          font-size: 14px;
          line-height: 1.4;
          letter-spacing: 0.01em;
          font-weight: 400;
        }

        @media screen and (max-width: 540px) {
          flex-basis: calc(100vw - 40px);
          height: calc(100vh - 100px);
          margin: 0 20px;
          padding: 40px 30px;
          display: flex;
          flex-direction: column;

          .id {
            flex-basis: 60%;
          }

          .portrait {
            padding-bottom: 30px;
            margin-right: -40px;
          }

          .bio {
            margin-top: 30px;
            flex: 0 1 auto;
            overflow: scroll;
          }
        }
      }
    }

    .cardnav {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-start;
      transform: translateY(-50%);

      a {
        margin: 0 7.5px;
      }

      @media screen and (max-width: 540px) {
        position: fixed;
        left: 0;
        bottom: 20px;
        width: 100%;
        transform: translateY(0);
      }
    }
  }
}

.btn-filter {
  &:hover {
    cursor: pointer;
  }
}
