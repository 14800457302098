/**
* Basic typography style for copy text
*/
body {
    font: 500 18px/1.4 $text-font-stack;

    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
}

h1 {
    font-family: 'Outage';
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    color: #c4c4c4;

    @media screen and (max-width: 800px) {
        font-size: 28px;
    }
}

h2 {
    margin: 0 0 40px;
    font-family: 'Outage';
    font-size: 38px;
    font-weight: 700;
    line-height: 1.1;
    color: #c4c4c4;

    @media screen and (max-width: 800px) {
        font-size: 22px;
    }
}

h3 {
    margin: 0 0 20px;
    font-family: 'Outage';
    font-size: 28px;
    font-weight: 700;
    line-height: 1.1;
    color: #c4c4c4;

    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
}

h4 {
    font-size: 20;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0.02em;
    color: #c4c4c4;
}

p {
    margin: 0 0 1.2em;
    color: #c4c4c4;

    &.lg {
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 0.01em;
    }

    &.semi {
        font-weight: 600;
        letter-spacing: 0.01em;
    }

    &.sm {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0.01em;
    }

    &.xs {
        font-size: 14px;
        letter-spacing: 0.01em;
    }

    &.footnote {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.01em;
    }
 
    &.section-label {
        font-size: 18px;
        font-weight: 800; 
        letter-spacing: 0.1em;
        line-height: 1.2;
        text-transform: uppercase;

        @media screen and (max-width: 800px) {
            font-size: 15px;
        }

        &.lg {
            font-size: 21px;
            line-height: 1.2;
        }

        &.sm {
            font-size: 16px;
            line-height: 1.2;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}