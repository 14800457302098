// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
    overflow-x: hidden;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    &.overlay-open {
        overflow: hidden;

        .team-overlay {
            transform: scale(1);
            opacity: 1;
            z-index: 999;
            pointer-events: auto;

            .cards {
                transform: scale(1);
                opacity: 1;
                transition-delay: 0s;
            }
        }
    }
}

a {
    text-decoration: none;
    color: inherit;
}

strong {
    font-weight: 700;
}

img {
    display: block;
    max-width: 100%;
}

figure {
    display: block;
    margin: 0;
    padding: 0;
}

section {
    position: relative;
    z-index: 5;
}

.grid {
    display: grid;

    &.grid-3 {
        grid-template-columns: repeat(3, 1fr);
    }
}

ul {
    margin: 0;
    padding: 0 0 0 50px;
    list-style: disc outside none;

    li {
        margin-bottom: 30px;
        padding-left: 50px;
    
        &::marker {
            color: $candyneon;
        }
    
        &:last-child {
            margin: 0;
        }
    }
}
